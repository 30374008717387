@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "SpaceMono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1B1A21;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SpaceMono";
  src: url("./assets/fonts/SpaceMono-Regular.ttf");
}

/* @font-face {
  font-family: 'SpaceMono';
  font-style: normal;
  font-weight: 700;
  src: url('https://fonts.gstatic.com/s/spacemono/v7/i7dMIFZifjKcF5UAWdDRYERx8f13kCr6TMCyvkc.ttf') format('truetype');
} */