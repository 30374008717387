.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #999 #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1b1a21;
  border-radius: 10px;
  margin: 0 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 0.45rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.custom-scrollbar::-webkit-scrollbar-corner {
  background-color: #1b1a21;
  border-radius: 10px;
}

.list-scrollbar {
  scrollbar-color: #999 #f5f5f5;
}

.list-scrollbar::-webkit-scrollbar {
  width: 0rem;
  height: 5px;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(27, 26, 33, 0.5) !important;
  backdrop-filter: blur(4px);
}

.contact_us_bg {
  background-image: url("/src/assets/images/shapes/contact-us.svg");
}

@keyframes circularMovement {
  0%,
  100% {
    transform: translate(0, 0) rotate(8deg);
  }

  25% {
    transform: translate(calc(10px * cos(45deg)), calc(10px * sin(45deg)))
      rotate(8deg);
  }

  50% {
    transform: translate(calc(10px * cos(90deg)), calc(10px * sin(90deg)))
      rotate(8deg);
  }

  75% {
    transform: translate(calc(10px * cos(135deg)), calc(10px * sin(135deg)))
      rotate(8deg);
  }
}

.animate-wiggle {
  animation: circularMovement 8s linear infinite;
}

@keyframes wave {
  0%,
  50%,
  100% {
    transform: translateY(8px);
  }

  25%,
  75% {
    transform: translateY(-8px);
  }
}

@keyframes wave-mobile {
  0%,
  50%,
  100% {
    transform: translateY(2px);
  }

  25%,
  75% {
    transform: translateY(-2px);
  }
}

.wave-text-1 {
  display: inline-block;
  animation: wave 5s 0.2s ease-in-out infinite;
}

.wave-text-2 {
  display: inline-block;
  animation: wave 5s 0.6s ease-in-out infinite;
}

.wave-text-3 {
  display: inline-block;
  animation: wave 5s 0.8s ease-in-out infinite;
}

@media screen and (max-width: 768px) {
  .wave-text-1,
  .wave-text-2,
  .wave-text-3 {
    animation-name: wave-mobile;
  }
}

@media screen and (max-width: 281px) {
  .extra_small_devices {
    text-align: center;
  }
}

.line_height {
  line-height: 3.4rem !important;
}

@media screen and (max-width: 821px) {
  .line_height {
    line-height: 2.5rem !important;
  }
}

@media screen and (min-width: 2559px) {
  .line_height {
    line-height: 4.2rem !important;
  }
}

/* Add this to your stylesheet or create a new one */
.loading-skeleton {
  background: linear-gradient(90deg, #070507 25%, #1b1a21 50%, #292733 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.statistics_card {
  background: url("../src/assets/images/statistics-card.png");
}

.statistics_bg {
  background: url("../src/assets/images/statistics-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
